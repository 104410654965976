import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private loc: Location) { }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = this.loc.path();
    const name = path.slice(1, path.indexOf('/', 1));

    if (!this.userService.isAuthenticated() || !this.userService.isActiveToken()) {
      const keys = Object.keys(localStorage);
      keys.forEach((key) => { localStorage.removeItem(key) });
      this.router.navigate([name + '/bienvenido']);
      return false;
    } else
      return true;
  }

}
