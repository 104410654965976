import { Injectable } from '@angular/core';
import { HttpClient, JsonpInterceptor } from '@angular/common/http';
import { API_ENDPOINT } from '../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserService } from './user.service';


const GAME_URL = `${API_ENDPOINT}/answer`;
const HAS_TRY_ULR = `${API_ENDPOINT}/has-try`;
const CATEGORIES_KEY = `categoriesData`;
const GAME_KEY = `sd5g46sd5f4g1154dsfa`;
const USED_KEY = 'isUsedComponent'

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private http:HttpClient,
              private authService:UserService) { }

  postAnswer(optionId:number, duration:number, live:number, categoryId:number|null=null ){
    if(this.authService.checkToken)
      return this.http.post(GAME_URL, {option_id: optionId, duration, category_id: categoryId, try: live});
    else{
      this.authService.refreshToken().then( (res) =>{
          return this.http.post(GAME_URL, {option_id: optionId, duration, category_id: categoryId, try: live});
      });
    }
  }
  setAnswered(data){
    localStorage.setItem( GAME_KEY, data);
  }
  getAnswered(){

  }
  setScore(score){
    localStorage.setItem(GAME_KEY, JSON.stringify(score) );
  }
  getScore(){
    const score = localStorage.getItem(GAME_KEY);
    if(score != null)
      return JSON.parse(score);
  }
  deleteScore(){
    const score = localStorage.getItem(GAME_KEY);
    if(score != null){
      localStorage.removeItem(GAME_KEY);
    }
  }
  hasScore(): boolean {
    return localStorage.getItem(GAME_KEY) != null;
  }
  setComponentUsed(param:boolean): void{
    if(param){
      localStorage.setItem(USED_KEY, 'true');
    }else
      localStorage.setItem(USED_KEY, 'false');
  }
  getUsedComponent():boolean{
    const data = localStorage.getItem(USED_KEY);
    return JSON.parse(data);
  }
  hasTryInCategory(category: number) {
    const url = HAS_TRY_ULR + '/' + category;

    return this.http.get(url);
  }
}