import { Component, ElementRef, ViewChild } from '@angular/core';
import { StartupService, Setting } from './services/startup.service';
import { STORAGE_ENDPOINT } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor (private startupService: StartupService) {}
  title = 'seguramente';
  setting: Setting;

  ngOnInit(): void {
    this.setting = this.startupService.getStartupData();
  }

  ngAfterViewInit(){

    document.querySelectorAll( "link[ rel ~= 'icon' i]" )[0].setAttribute('href',this.getUrlFavicon(this.setting.favicon));
    
  }

  getUrlFavicon(str:string): string {
    return STORAGE_ENDPOINT + str; 
  }
}
