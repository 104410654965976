import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    console.error(err)

    const errorMsgElement = document.querySelector('#errorMsgElement');
    let message = 'La inicialización de la aplicación falló';
    if (err) {
        if (err.error) {
            message = message + ': ' + err.error;
        } else {
            message = message + ': ' + err;
        }
    }
    errorMsgElement.textContent = message;

  });
