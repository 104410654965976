import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { StartupService, SETTING_URL } from './startup.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor( private authService: UserService, private loc: Location, private setServ: StartupService )
  {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
  {
    const path     = this.loc.path();
    const name     = path.slice(1, path.indexOf('/', 1));
    const token    = this.authService.getToken();
    if( token )
    {
      const headers = new HttpHeaders({ "authorization": "Bearer " + token, "path_name": name,}); 
      const clone   = request.clone({ headers });
      return next.handle( clone );
    }  else {
      const headers = new HttpHeaders({"path_name": name,}); 
      const clone   = request.clone({ headers });
      return next.handle( clone ); 
    } 
  }
}

