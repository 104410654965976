import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINT } from '../../environments/environment';

export const SETTING_URL = `${API_ENDPOINT}/setting`;
const SETTING_KEY = `settingData`;

export class Setting {
  triviaId: number;
  triviaName: string;

  id: number;
  primaryColor?: string;
  fontColor?: string;
  bgGradientColor?: string;
  bgGradientColor2?: string;
  borderGradientColor?: string;
  borderGradientColor2?: string;
  created_at?: string;
  updated_at?: string;
  resource_url?: string;
  favicon?: string;
  logotype1?: string;
  logotype2?: string;
  lives?: number;
  trys?: number;
  signupFree: boolean;

  constructor(data: any) {
    this.triviaId = data.trivia_id,
      this.triviaName = data.trivia_name,

      this.id = data.id;
    this.primaryColor = data.primary_color;
    this.fontColor = data.secondary_color ? data.secondary_color : '#fff';
    this.bgGradientColor = data.bg_gradient_color;
    this.bgGradientColor2 = data.bg_gradient_color_2;
    this.borderGradientColor = data.border_gradient_color ? data.border_gradient_color : '#fff';
    this.borderGradientColor2 = data.border_gradient_color_2;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.favicon = data.favicon;
    this.logotype1 = data.logotype_1;
    this.logotype2 = data.logotype_2;
    this.lives = data.lives;
    this.trys = data.trys;
    this.resource_url = data.resource_url;

    this.signupFree = data.signup_free;
  }
}
@Injectable({
  providedIn: 'root'
})
export class StartupService {

  private setting: Setting;

  constructor(private http: HttpClient) { }

  public getConfig(name: string) {
    const url = `${SETTING_URL}/${name}`;
    const headers = {};
    return this.http.get(url, { headers });
  }
  public setConfig(data: any): boolean {
    if (data) {
      localStorage.setItem(SETTING_KEY, JSON.stringify(data));
      this.setting = new Setting(data);
      return true;
    }
    else
      return false;
  }
  public getStartupData(): Setting | null {
    const setting = this.setting;

    if (!setting) {
      const data = localStorage.getItem(SETTING_KEY);
      if (data !== null)
        return JSON.parse(data);
      else
        return null;
    } else
      return this.setting;
  }
  public isSignedUpFree(): boolean {
    const setting = this.setting;

    if (setting)
      return setting.signupFree;
    else {
      const data = localStorage.getItem(SETTING_KEY);

      if (data !== null) {
        const setting = new Setting(JSON.parse(data));
        return setting.signupFree;
      } else
        return null;
    }
  }
  public getTriviaName() {
    const setting = this.setting;

    if (setting) {
      return setting.triviaName;
    } else {
      const data = localStorage.getItem(SETTING_KEY);

      if (data != null) {
        const setting = new Setting(JSON.parse(data));
        return setting.triviaName;
      } else
        return null;
    }
  }
  /*   public getTriviaId() {
  
      const setting = this.setting;
  
      if(setting || setting != undefined) {
        return setting.triviaId.toString();
      } else {
        const data = localStorage.getItem(SETTING_KEY);
        if(data != null) {
          const setting = new Setting(JSON.parse(data));
          if(setting.triviaId)
            return setting.triviaId.toString();
        } else
          return null;
      }
  
    } */
}
