<div class="block-container" *ngIf="showComponent()">
    <div class="block-top">
        <a class="btn-back" *ngIf="showBackArrow()" (click)="onClickBackUrl()">
            <img src="assets/img/btn-arrow-left-yellow.svg" />
        </a>
        <!--
            <img src="assets/img/brand-seguramente.svg" [ngClass]="getClass()"/>
        -->
        <img *ngIf="logotype2 != null" src="{{ getUrlPicture(logotype2) }}" [ngClass]="getClass()" />
        <img *ngIf="logotype2 == null" src="assets/img/brand-seguramente.svg" [ngClass]="getClass()" />
    </div>
</div>