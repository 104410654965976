import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GameComponent } from '../screens/game/game.component';
import { GameService } from '../services/game.service';

@Injectable({
  providedIn: 'root'
})
export class GameGuard implements CanDeactivate<GameComponent> {
  constructor(private gameSer: GameService) {}
  canDeactivate(
    component: GameComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
    ): 
      Observable<boolean | UrlTree> | 
      Promise<boolean    | UrlTree> | 
      boolean | UrlTree {

        if( this.gameSer.getUsedComponent() )
          return false
        else
          return true
  }
  
}
