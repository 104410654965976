import { Component, OnInit } from '@angular/core';
import { STORAGE_ENDPOINT } from 'src/environments/environment';
import { StartupService, Setting } from 'src/app/services/startup.service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {

  logotype1:string | null;
  logotype2:string | null;
  constructor( private startupService: StartupService) { }

  ngOnInit(): void {
    const setting : Setting = this.startupService.getStartupData();
    if(typeof setting == "object"){
      if("logotype1" in setting)
        this.logotype1 = setting.logotype1;
      if("logotype2" in setting)
        this.logotype2 = setting.logotype2;
    }
  }

  getUrlPicture(str:string): string {
    return STORAGE_ENDPOINT + str;
  }

}
