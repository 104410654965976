import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { StartupService, Setting } from 'src/app/services/startup.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  
  @ViewChild ('appContainer', {static: false}) container: ElementRef<HTMLElement>;

  setting: Setting;
  constructor(private render: Renderer2,
              private stratupService: StartupService) { }
  ngOnInit(): void {
    this.setting = this.stratupService.getStartupData();
  }
  ngAfterViewInit() {
    this.container.nativeElement.style.setProperty('--text-color', this.setting.fontColor );
    this.container.nativeElement.style.setProperty('--border-color', this.setting.borderGradientColor);

    this.container.nativeElement.style.background = this.setting.bgGradientColor;
  }
  getUrlPicture(str:string): string {
    return str;
  }
}
