import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

import { STORAGE_ENDPOINT } from 'src/environments/environment';
import { StartupService, Setting } from 'src/app/services/startup.service';


@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {

  public currentRoute: string;
  public previousUrl: string;
  logotype2: string | null;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private startupService: StartupService) {
    this.currentRoute = router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentRoute;
        this.currentRoute = event.url;
      }
    });
  }


  ngOnInit(): void {
    const setting: Setting = this.startupService.getStartupData();
    if (typeof setting == "object") {
      if ("logotype2" in setting)
        this.logotype2 = setting.logotype2;
    }
  }

  getUrlPicture(str: string): string {
    return STORAGE_ENDPOINT + str;
  }

  getUrl(): string {
    return this.currentRoute.slice(this.currentRoute.indexOf('/', 1)).split('/')[1];
  }

  showComponent(): boolean {
    let returnValue: boolean = false;
    switch (this.getUrl()) {
      case 'indice':
      case 'categorias':
      case 'ingresar':
      case 'ranking':
      case 'resultado':
      case 'registro':
      case 'bienvenido':
      case 'activar-cuenta':
      case 'ranking-categoria':
      case 'ranking-general':
      case 'crear-contrasena':
      case "restablecer-contrasena":
      case 'ranking-listado-categorias':
        returnValue = true;
        break;
      default:
        returnValue = false;
        break;
    }

    return returnValue;
  }

  getClass(): string {
    if (this.isLogin() || this.isSingup())
      return "login signin-form brand-form"

    return 'brand';
  }
  showBackArrow(): boolean {
    if (this.isLogin() || this.isResults() || this.isSingup() || this.isWelcome() || this.isPassword() || this.isAccount() || this.isRank() || this.isRecoverPass())
      return false;
    return true;
  }
  isLogin(): boolean {
    return this.getUrl() === 'ingresar';
  }
  isResults(): boolean {
    return this.getUrl() === 'resultado';
  }
  isSingup(): boolean {
    return this.getUrl() === 'registro';
  }
  isWelcome(): boolean {
    return this.getUrl() === 'bienvenido';
  }
  isAccount(): boolean {
    return this.getUrl() === 'activar-cuenta';
  }
  isPassword(): boolean {
    return this.getUrl() === 'crear-contrasena';
  }
  isRank(): boolean {
    return this.getUrl() === 'ranking-categoria' || this.getUrl() === 'ranking-general' || this.getUrl() === 'ranking';
  }
  isRecoverPass(): boolean {
    return this.getUrl() === 'crear-contrasena';
  }
  onClickBackUrl(): void {

    if (this.previousUrl == '/') {
      const loc = this.location.path();
      const path = loc.slice(0, loc.indexOf('/', 1));
      this.router.navigate([path + '/indice']);
    } else
      this.router.navigate([this.previousUrl]);
  }
}
