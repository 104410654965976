import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINT } from '../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tokenize } from '@angular/compiler/src/ml_parser/lexer';



const LOGIN_URL       = `${API_ENDPOINT}/login`;
const LOGOUT_URL      = `${API_ENDPOINT}/logout`;
const JWT_REFRESH_URL =  `${API_ENDPOINT}/refresh-token`;
const SIGNUP_URL      = `${API_ENDPOINT}/signup`;
const ACTIVATED_URL   = `${API_ENDPOINT}/activate-account`;
const RECOVER_PASS_URL = `${API_ENDPOINT}/password-recovery`;
const RESET_PASS_URL  = `${API_ENDPOINT}/password-reset`;


const USER_KEY        = 'userData';


export class User
{
  id:       number;
  name:     string;
  email:    string;
  token:    string;
  idCard:  number;
  active: boolean;

  constructor( token: string, response: any )
  {
    this.email    = response.email;
    this.id       = response.id;
    this.name     = response.name;
    this.idCard   = response.id_card || response.idCard;
    this.token    = token;
    this.active   = response.active;
  }
}

export interface UserSignUp {
  id_card:string,
  name:string,
  password:string,
  password_confirmation:string,
  email:string,
  adicional: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public  loggedIn = false;
  private loginAction = new Subject<any>();
  private user: User;

  constructor(private http: HttpClient, ) { }

  async auth(credentials: any): Promise<any>
  {
    return this.http.post(LOGIN_URL, credentials).toPromise()
    .then( (response:any) => {
        this.setUser(response.access_token, response.user);
        return true;
    })
    .catch( (error: any) => {
      if(error.error.errors){
        const err = error.error.errors;
        const message = '';
        const key = Object.keys(err);

        return err[key[0]][0];
      }
      if(error.error)
        return error.error.message;
      });
  }
  async logout(): Promise<any>
  {
    const token   = this.getToken();
    const url     = LOGOUT_URL;
    const params  = null;
    const headers = null;

    if(token)
    return this.http.get( url, { headers } ).toPromise()
      .then( ( response: any ) =>
      {
        return this.clearUser();
      });
    else
      return this.clearStorage;
  }
  async signup(user:UserSignUp) {
    return this.http.post(SIGNUP_URL, user).toPromise()
    .then( (res:any) => {
      return res;
    })
    .catch( (error:any) => {
      return error; 
    });
  }
  getToken(): string | null
  {
    const user = this.getUser();

    if( user )
      return user.token;

    return null;
  }
  isAuthenticated(): boolean
  {
    return this.getToken() !== null;
  }
  isActiveToken(): boolean {
    const jwtToken = JSON.parse(atob(this.getToken().split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const now = Date.now();
    return expires.getTime() > now;
  }
  checkToken(): boolean {
    const jwtToken = JSON.parse(atob(this.getToken().split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const now = Date.now();
    const diference =( expires.getTime() - 60000) - now;
    return diference > 0;
  }
  async refreshToken() {
    const user = this.getUser();
    if(user){
      const url = JWT_REFRESH_URL;
      this.http.get(url).toPromise()
      .then( (response:any) => {
        this.setUser(response.access_token, response.user);
        return true;
      }).catch( (error: any) => {
        if(error.error)
          return error.error.message;
      });
    }
  }
  clearUser(): boolean
  {
    this.user = null;
    localStorage.removeItem( USER_KEY );

    return true;
  }
  private setUser( token: string, response: any ): boolean
  {
    if( token )
    {
      this.user = new User( token, response );
      localStorage.setItem( USER_KEY, JSON.stringify( this.user ) );

      return true;
    }
    return false;
  }
  getUser(): User
  {
    if( !this.user )
    {
      const user = localStorage.getItem( USER_KEY );
      if( user )
        this.user = JSON.parse( user );
    }
    return this.user;
  }
  clearStorage():void {
    localStorage.clear();
  }
  async activateUser(hash:string): Promise<any> {
    const url = ACTIVATED_URL + '/' + hash;
    return this.http.get(url).toPromise()
    .then( ( response:any ) => {
      this.setUser(response.access_token, response.user);
      return true;
      
    }).catch((error) => {
      if(error.error)
        return error.error.message;
      return error;
    })
  }
  passworRecovery(email): Promise<any> {
    return this.http.post(RECOVER_PASS_URL, email).toPromise()
    .then( response => {
      return response;
    })
    .catch( error => {
      if(error.error.errors){
        const err = error.error.errors;
        const message = '';
        const key = Object.keys(err);

        return err[key[0]][0];
      }
      if(error.error)
        return error.error.message;
    })
  }
  passwordReset(token, password) {
    const url = RESET_PASS_URL + '/' + token;

    return this.http.post(url, password).toPromise()
    .then( (response) => {
      return response
    }).catch( (error) => {
      console.error(error)
      return error.error.message
    });
  }
}
