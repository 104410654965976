import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadingStrategy, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { SignupGuard } from './guards/signup.guard';
import { GameGuard } from './guards/game.guard';
import { ResultGuard } from './guards/result.guard';


const routes: Routes = [
  {
    path: ':trivia',
    //redirectTo: '/bienvenidos',
    //pathMatch: 'full',
    children:[
      { path: 'bienvenido',
        loadChildren: () => import('./screens/splash/splash.module').then( (m) => m.SplashModule ),
      },

      { path: 'ingresar', 
        loadChildren: () => import('./screens/login/login.module').then( (m) => m.LoginModule ),
      },

      { path: 'registro', 
        canActivate: [SignupGuard],
        loadChildren: () => import('./screens/signup/signup.module').then( (m) => m.SignupModule) ,
      },

      { path: 'indice',
       canActivate: [AuthGuard],
       loadChildren: () => import('./screens/index/index.module').then( (m) => m.IndexModule ),
      },

      { path: 'categorias',
       canActivate: [AuthGuard],
       loadChildren: () => import('./screens/categories/categories.module').then( (m) => m.CategoriesModule ),
      },

      { path: 'ranking',
       canActivate: [AuthGuard],
       loadChildren: () => import('./screens/ranking/ranking.module').then( (m) => m.RankingModule ),
      },

      { path: 'ranking-general',
        canActivate: [AuthGuard],
        loadChildren: () => import('./screens/ranking-general/ranking-general.module').then( (m) => m.RankingGeneralModule ),
      },
      {
        path: 'ranking-listado-categorias',
        canActivate: [AuthGuard],
        loadChildren: () => import('./screens/category-list/category-list.module').then( (m) => m.CategoryListModule),
      },
      {
        path: 'ranking-categoria/:category',
        canActivate: [AuthGuard],
        loadChildren: () => import('./screens/ranking-category/ranking-category.module').then( (m) => m.RankingCategoryModule),
      },
      { path: 'jugar/:id',
        canActivate: [AuthGuard],
        canDeactivate: [GameGuard],
        loadChildren: () => import('./screens/game/game.module').then( (m) => m.GameModule ),
      },
      { path: 'resultado',
        canActivate: [AuthGuard, ResultGuard],
        loadChildren: () => import('./screens/result/result.module').then( (m) => m.ResultModule ),
      },
      {
        path: 'crear-contrasena/:hash',
        loadChildren: () => import('./screens/create-password/create-password.module').then( (m) => m.CreatePasswordModule),
      },
      {
        path: 'activar-cuenta/:hash',
        loadChildren: () => import('./screens/activate-account/activate-account.module').then( (m) => m.ActivateAccountModule),
      },
      {
        path: 'restablecer-contrasena',
        loadChildren: () => import('./screens/reset-password/reset-password.module').then( (m) => m.ResetPasswordModule),
      },

      {
        path:'pagina-no-encontrada',
        loadChildren: () => import('./screens/page-not-found/page-not-found.module').then( (m) => m.PageNotFoundModule ),
        pathMatch: 'full',
      },
      { path: '**', redirectTo: '/pagina-no-encontrada', pathMatch: 'full' },
    
    ],
  },

  {
    path:'pagina-no-encontrada',
    loadChildren: () => import('./screens/page-not-found/page-not-found.module').then( (m) => m.PageNotFoundModule),
    pathMatch: 'full',
  },

  { path: '**', redirectTo: '/pagina-no-encontrada', pathMatch: 'full' },

];

@NgModule({
  
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules} )
  ],

  exports: [RouterModule],
})

export class AppRoutingModule { }
