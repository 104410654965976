import { StartupService } from "../services/startup.service";
import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import { Location } from '@angular/common';

export function initializeApp(startupService: StartupService, loc: Location, router: Router) {
    const path = loc.path();
    const name = path.slice(1, path.indexOf('/', 1));
    const keys = Object.keys(localStorage);
    keys.forEach((key) => { localStorage.removeItem(key) });


    return () => new Promise((resolve, reject) => {
        // attempt to refresh token on app start up to auto authenticate MAKE_ME
        const data = startupService.getConfig(name);
        data.subscribe((res: any) => {
            const loadgin = startupService.setConfig(res.data);
            if (loadgin)
                resolve(res.data);

        }, (error) => {
            console.error(error)
            router.navigate(['/pagina-no-encontrada'])
            loc.go('/pagina-no-encontrada');
            reject(error);
        });
    });

}